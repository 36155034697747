import { Injectable } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { resourcesConfig } from '@proman/resources';
import { FilterService } from '@proman/services/filter.service';
import { Product, SystemOptions } from "@proman/interfaces/entity-interfaces";
import { CurrUser, TableField } from '@proman/interfaces/object-interfaces';
import { PromanStateService } from '../../shared/services/proman-state.service';
import { ProductEntityInterface } from '@proman/resources/product';
import { isDefinedNotNull, removeHtmlTags, uniq } from '@proman/utils';
import { Store } from "@ngrx/store";
import { getSystemOptions } from "@proman/store/system-options";
import { getCurrUser } from '@proman/store/curr-user';
import { filter } from 'rxjs/operators';
import { WarehousesService } from '@proman/services/warehouses.service';

@Injectable()
export class ProductsService {
    productEntity: ProductEntityInterface;
    systemOptions: SystemOptions;
    currUser: CurrUser;
    getExtraParameters = resourcesConfig['product'].params.extraParameters;

    constructor(
        private Entity: Entity,
        private Filter: FilterService,
        private PromanState: PromanStateService,
        private Warehouses: WarehousesService,
        private store: Store,
    ) {
        this.productEntity = this.Entity.get('product');
        this.store.select(getSystemOptions).subscribe(value => this.systemOptions = value);
        this.store.select(getCurrUser).pipe(filter((val) => !!val)).subscribe((value) => this.currUser = value);
    }

    private getName(customerAlias: any, articleCode?: any, name?: string) {

        if (!name) {
            this.Filter.translate('product');

        }

        return name;
    }

    getNew = (customerAlias: any) => {
        return {name: this.getName(customerAlias), quantity: 1};
    };

    private static getOrders(rowData: Product) {
        if (rowData.orderProducts) {
            return rowData.orderProducts
                .map((item) => item.order)
                .map((item) => item.name)
                .join(', ')
        }
        return '-';
    }

    getEntity = (): ProductEntityInterface => {
        return this.productEntity;
    };

    getStorageInfo = (id: number) => {
        return this.Entity.get('production_product')
            .search({
                'orderProduct.product.id': id,
                'join': [
                    'production',
                    'orderProduct',
                    'orderProduct.order',
                    'orderProduct.order.customer'
                ]
            })
            .then((response: any) => {
                return response
                    .map((productionProduct: any) => {
                        const order = productionProduct.orderProduct.order;

                        return {
                            orderId: order.id,
                            orderName: order.name,
                            orderNumber: order.number,
                            customerName: order.customer.name,
                            quantity: productionProduct.packagedQuantity,
                            date: productionProduct.production.deadline
                        };
                    });
            });
    };

    getPackageName = (item: any) => {
        return `${item.material.name} ${this.Filter.quantName(item)}`;
    };

    getPackageValue = (row: any) => {
        if (!row.package) return '';

        return this.getPackageName(row.package);
    };

    getFields = (): TableField[] => {
        if (this.currUser.bookkeepingUser) {
            return [
                {
                    name: 'ID',
                    key: 'id'
                },
                {
                    name: '',
                    key: 'photo',
                    formatter: 'image',
                },
                {
                    name: 'name',
                    key: 'name'
                },
                {
                    name: 'lot_number',
                    key: 'lotNumber',
                    hidden: true,
                },
                {
                    name: 'article',
                    key: 'article.name',
                },
                {
                    name: 'unit',
                    key: 'unit',
                },
                {
                    name: 'weight',
                    key: 'weight',
                    getValue: (row: Product) => `${row.weight || 0}${row.article?.weightUnit || ''}`,
                },
                {
                    name: 'quantity',
                    key: 'storedQuantity',
                    filter: {
                        type: 'numeric',
                        value: '>0',
                        userValueExpiry: 1000 * 60 * 60 * 24
                    },
                    stats: ['sum']
                },
                {
                    name: 'minimum_quantity',
                    key: 'minimumQuantity',
                    stats: ['sum']
                },
                {
                    name: 'reserved_quantity',
                    key: 'reservedQuantity',
                    stats: ['sum']
                },
                {
                    name: 'remnant',
                    key: 'remnantQuantity',
                    hidden: true,
                    filter: null,
                    stats: ['sum']
                },
                {
                    name: 'expire_duration',
                    key: 'article.productExpireAfter',
                    hidden: true,
                },
                {
                    name: 'created_at',
                    key: 'createdAt',
                    formatter: 'dateTime'
                },
                this.Warehouses.getTableField(),
                {
                    name: 'barcode',
                    key: 'productBarcode.value',
                    hidden: true,
                },
                {
                    name: 'vat',
                    key: 'vat',
                    getValue: (item: Product) => item.vat ? (item.vat?.name + ' - ' + item.vat?.percentage + '%') : '',
                    filter: {
                        type: 'search',
                        keys: ['vat.name', 'vat.percentage'],
                    },
                    hidden: true,
                },
                {
                    name: 'price',
                    key: 'cost',
                    formatter: 'money',
                    formatterConfig: 4,
                    hidden: true,
                    acl: 'product.show_price',
                },
                {
                    name: 'sale_price',
                    key: 'salePrice',
                    formatter: 'money',
                    formatterConfig: 4,
                    hidden: true,
                    acl: 'product.show_price',
                },
                {
                    name: 'suppliers',
                    key: 'suppliers.name',
                    getValue: (product: Product) => product.suppliers.map((category) => category.name).join(', '),
                },
            ];
        }

        return [
            {
                name: 'ID',
                key: 'id'
            },
            {
                name: '',
                key: 'photo',
                formatter: 'image',
            },
            {
                name: 'name',
                key: 'name'
            },
            {
                name: 'lot_number',
                key: 'lotNumber',
                hidden: true,
            },
            {
                name: 'code',
                key: 'article.altName'
            },
            {
                name: 'article',
                acl: 'article.display',
                key: 'article.name',
                state: {
                    name: 'Article',
                    key: 'articleId',
                    id: 'article.id'
                }
            },
            {
                name: 'weight',
                key: 'weight',
                getValue: (row: Product) => `${row.weight || 0}${row.article?.weightUnit || ''}`,
            },
            {
                name: 'type',
                key: 'article.type.name'
            },
            {
                name: 'article_categories',
                key: 'article.categories.name',
                getValue: (product: Product) => product.article.categories.filter((category) => !!category).map((category) => category.name).join(', '),
                hidden: true,
            },
            {
                name: 'orders',
                key: 'orderProducts.order.name',
                getValue: ProductsService.getOrders,
                sort: null,
                filter: {
                    type: 'search',
                    keys: [
                        'orderProducts.order.number',
                        'orderProducts.order.number',
                        'orderProducts.order.name'
                    ]
                },
                maxLength: 5
            },
            {
                name: 'order_id',
                key: 'orderProducts.order.id',
                getValue: (row: Product) => {
                    let result: number[] = [];

                    row.orderProducts?.forEach((oP) => {
                        result.push(oP.order.id);
                    });

                    result = uniq(result);

                    return result.join(', ');
                },
                sort: null,
            },
            {
                name: 'validity',
                key: 'validity',
                translate: true,
            },
            {
                name: 'customer',
                key: 'orderProducts.order.customer.name',
                getValue: (item: Product)  => {
                    const result: string[] = [];
                    item.orderProducts?.forEach((oProduct) => {
                        if (oProduct.order && oProduct.order.customer) result.push(oProduct.order.customer.name);
                    });

                    return uniq(result).join(', ');
                },
            },
            {
                name: 'quantity',
                key: 'storedQuantity',
                filter: {
                    type: 'numeric',
                    value: '>0',
                    userValueExpiry: 1000 * 60 * 60 * 24
                },
                stats: ['sum']
            },
            {
                name: 'minimum_quantity',
                key: 'minimumQuantity',
                stats: ['sum']
            },
            {
                name: 'reserved_quantity',
                key: 'reservedQuantity',
                stats: ['sum']
            },
            {
                name: 'remnant',
                key: 'remnantQuantity',
                hidden: true,
                filter: null,
                stats: ['sum']
            },
            {
                name: 'package_quantity',
                key: 'packagingQuantity',
                stats: ['sum']
            },
            {
                name: 'reserved_package_quantity',
                key: 'reservedPackagingQuantity',
                stats: ['sum']
            },
            {
                name: 'expire_date',
                key: 'expireAt',
                formatter: 'dateTime',
                formatterConfig: '_datetime_js',
                hidden: true,
            },
            {
                name: 'parameters',
                key: 'parameters',
                filter: {
                    type: 'search',
                    keys: ['parameters.parameter.name', 'parameters.parameter.alias', 'parameters.value'],
                },
                formatter: 'parameters',
                extraJoins: [
                    'parameters',
                    'parameters.parameter',
                ],
                preloadParametersData: true,
                hidden: true,
            },
            {
                name: 'status',
                key: 'status',
                translate: true
            },
            {
                name: 'created_at',
                key: 'createdAt',
                formatter: 'dateTime'
            },
            {
                name: 'warehouse',
                key: 'warehouseLocation.warehouse.name',
                getValue: (item: Product) => item.warehouseLocation && item.warehouseLocation.warehouse.name || '',
                hidden: true,
            },
            this.Warehouses.getTableField(),
            {
                name: 'store_location',
                key: 'storeLocation',
                hidden: true,
            },
            {
                name: 'store_name',
                key: 'storeName'
            },
            {
                name: 'barcode',
                key: 'productBarcode.value',
                hidden: true,
            },
            {
                name: 'vat',
                key: 'vat',
                getValue: (item: Product) => item.vat ? (item.vat?.name + ' - ' + item.vat?.percentage + '%') : '',
                filter: {
                    type: 'search',
                    keys: ['vat.name', 'vat.percentage'],
                },
                hidden: true,
            },
            {
                name: 'sale_price',
                key: 'salePrice',
                formatter: 'money',
                formatterConfig: 4,
                hidden: true,
                acl: 'product.show_price',
            },
            {
                name: 'capacity',
                key: 'capacity',
                hidden: true,
                formatter: 'numeric',
                formatterConfig: this.systemOptions.defaultDecimalPlacesProductWeight,
                getValue: (item: Product) => +item.storedQuantity * +item.capacity,
                stats: ['capacity']
            },
            {
                name: 'extent',
                key: 'extent',
                formatter: 'numeric',
                formatterConfig: this.systemOptions.defaultDecimalPlacesProductWeight,
                getValue: (item: Product) => +item.storedQuantity * +item.extent,
                hidden: true,
                stats: ['extent']
            },
            {
              name: 'comments',
              key: 'comments',
              getValue: (row) => isDefinedNotNull(row.comments) ? removeHtmlTags(row.comments) : '',
            },
            {
              name: 'customer_comments',
              key: 'customerComments',
              getValue: (row) => isDefinedNotNull(row.customerComments) ? removeHtmlTags(row.customerComments) : '',
            },
        ];
    };

    evaluatePackageQuantity(product: Product, packagingQuantity: number) {
        return this.productEntity
            .evaluatePackageQuantity({ id: product.id, packagingQuantity})
            .then((response: { data: number }) => response.data);
    }

    evaluateProductQuantity(product: Product, productQuantity: number) {
        return this.productEntity.evaluateProductQuantity({ id: product.id, productQuantity })
            .then((response: { data: number }) => response.data);
    }

}
