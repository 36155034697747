import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PublicSystemOptions, WarehouseLocation } from '../interfaces/entity-interfaces';
import { TableField } from '../interfaces/object-interfaces';
import { getPublicSystemOptions } from '../store/system-options';
import { QueryExpressionService } from './query-expression.service';

export interface WarehoseAutocompleteConfigInterface {
    label: string;
    entity: 'warehouse_location';
    entityParams: { [key: string]: unknown };
    searchFields: string[];
    isNone?: boolean;
    getOptionName: (location: WarehouseLocation) => string;
}

@Injectable({ providedIn: 'root' })
export class WarehousesService {
  systemOptions: PublicSystemOptions;
  namespace: string;

    constructor(
      private store: Store,
      private QueryExpression: QueryExpressionService,
    ) {
      this.store.select(getPublicSystemOptions).subscribe((value) => {
        this.systemOptions = value;
        if (value.corporateChild) this.namespace = value.namespace;
      })
    }

    getTableField(): TableField {
        return {
            name: 'warehouse_location',
            key: 'warehouseLocation.name',
            getValue: (item: { warehouseLocation: WarehouseLocation }) => item.warehouseLocation?.warehouse ? `${item.warehouseLocation.warehouse.name}${item.warehouseLocation.warehouse.divider}${item.warehouseLocation.name}` : '',
            filter: {
                type: 'search',
                keys: ['warehouseLocation.name', 'warehouseLocation.warehouse.name'],
            },
            hidden: true
        }
    }

    getAutocompleteConfig(): WarehoseAutocompleteConfigInterface {
      const params: WarehoseAutocompleteConfigInterface = {
        label: 'warehouse_location',
        entity: 'warehouse_location',
        entityParams: { join: ['warehouse'] },
        searchFields: ['name', 'warehouse.name'],
        isNone: true,
        getOptionName: (warehouseLocation: WarehouseLocation) => {
          let string = '';
          if (warehouseLocation) {
            string = `${warehouseLocation.warehouse?.name}${warehouseLocation.warehouse?.divider}${warehouseLocation.name}`;
            if (this.systemOptions.corporate && !this.namespace && warehouseLocation.warehouse && warehouseLocation.warehouse.namespace) {
              string = `${warehouseLocation.warehouse?.namespace}${warehouseLocation.warehouse?.divider}${warehouseLocation.warehouse?.name}${warehouseLocation.warehouse?.divider}${warehouseLocation.name}`;
            }
          }
          return string;
        }
      };

      if (this.namespace) {
        Object.assign(params.entityParams, { 'warehouse.namespace': this.QueryExpression.orNull(this.namespace) });
      }

      return params;
    }

}
