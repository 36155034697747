import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { mapId, roundNumber } from '@proman/utils';
import { LocalStorageService } from '@proman/services/local-storage.service';
import { ProductionProduct, WarehouseLocation } from '@proman/interfaces/entity-interfaces';
import { UI_CLOSE_AFTER_BARCODE_PRINT, UiPreferencesService } from '@proman/services/ui-preferences.service';
import { WarehoseAutocompleteConfigInterface, WarehousesService } from '@proman/services/warehouses.service';

@Component({
    selector: 'pm-production-container-create-dialog',
    template: `
        <pro-dialog-title [title]="productionProduct.orderProduct.product.name"></pro-dialog-title>
        <div mat-dialog-content>
            <pm-barcode></pm-barcode>
            <pm-txt [config]="{ label: 'quantity_per_container', validators: { number: true } }"
                    [value]="quantityPerContainer"
                    (onChange)="setQuantityPerContainer($event)"></pm-txt>
            <pm-txt [config]="{ label: 'product_container_quantity', validators: { number: true } }"
                    [value]="batchSize"
                    (onChange)="setContainersQuantity($event)"></pm-txt>
            <pm-txt [config]="{ label: 'product_quantity', validators: { number: true } }"
                    [value]="quantity"
                    (onChange)="setQuantity($event)"
                    [disabled]="true"></pm-txt>
            <pro-editable-container label="accounted_quantity">
                {{ totalQuantity }}</pro-editable-container>

            <pro-checkbox [value]="isAutoCloseDialog"
                         [config]="{ label: 'close_on_print' }"
                         (onChange)="setAutocloseDialog($event)"></pro-checkbox>

            <pro-autoc [value]="warehouseLocation"
                       [config]="warehouseAutocompleteConfig"
                       (onChange)="setWarehouseLocation($event)"></pro-autoc>

        </div>
        <pro-dialog-actions>
            <pro-btn (onClick)="print()"
                    [label]="'print' | translate"
                    theme="accent"></pro-btn>
            <pro-btn (onClick)="print(true)"
                    [label]="('print_to_store' | translate)"
                    theme="accent"></pro-btn>
            <pro-btn (onClick)="moveToStore()"
                     [label]="('move_to_store' | translate)"
                     theme="accent"></pro-btn>
            <pro-btn *ngIf="showCopy()"
                    (onClick)="reprint()"
                    [label]="'copy' | translate"
                    theme="accent"></pro-btn>
        </pro-dialog-actions>
    `
})

export class ProductionContainerCreateDialogComponent {
    productContainerEntity: any;
    barcodePrinterEntity: any;
    productionProduct: ProductionProduct;
    previousContainers: any = null;
    quantity: number;
    batchSize: number;
    isAutoCloseDialog: boolean;

    totalQuantity: number = 0;
    quantityPerContainer: number = 0;
    warehouseLocation: WarehouseLocation;
    warehouseAutocompleteConfig: WarehoseAutocompleteConfigInterface;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private LocalStorage: LocalStorageService,
        private UiPrefs: UiPreferencesService,
        private Warehouses: WarehousesService,
        private dialogRef: MatLegacyDialogRef<ProductionContainerCreateDialogComponent>,
    ) {
        this.barcodePrinterEntity = this.Entity.get('printer');
        this.productContainerEntity = this.Entity.get('product_container');
        this.isAutoCloseDialog = this.UiPrefs.get(UI_CLOSE_AFTER_BARCODE_PRINT) === null ?
            true :
            this.UiPrefs.get(UI_CLOSE_AFTER_BARCODE_PRINT);

        this.productionProduct = data.productionProduct;

        this.batchSize = +this.productionProduct.packagingQuantity || 1;
        this.quantity = +this.productionProduct.productionQuantity;

        this.calcQuantityPerContainer();

        this.warehouseAutocompleteConfig = Warehouses.getAutocompleteConfig();

    }

    print(moveToStore?: boolean) {
        if (this.batchSize && this.quantity) {
            this.productContainerEntity
                .createBatch({
                    printer: this.LocalStorage.get('barcodePrinter'),
                    barcode_template: this.LocalStorage.get('barcodeTemplate'),
                    productionProduct: this.productionProduct.id,
                    product: this.productionProduct.orderProduct.product.id,
                    quantity: this.quantityPerContainer,
                    batchSize: this.batchSize,
                    event: this.data.event.id,
                    moveToStore: moveToStore ? true : [],
                    warehouseLocation: this.warehouseLocation?.id || [],
                    printLabel: true,
                })
                .then((response: any) => {
                    this.previousContainers = response.data;

                    for (let container of this.previousContainers) {

                        container.product = this.productionProduct.orderProduct.product;

                        this.totalQuantity += +this.quantity;
                    }

                    if (this.isAutoCloseDialog) this.dialogRef.close(1);

                });
        }
    }

    reprint() {
        if (this.previousContainers) {
            this.barcodePrinterEntity.printProductContainer({
                printer: this.LocalStorage.get('barcodePrinter'),
                barcode_template: this.LocalStorage.get('barcodeTemplate'),
                productContainer: this.previousContainers.map(mapId)
            });
        }
    }

  moveToStore(moveToStore?: boolean) {
    this.productContainerEntity.createBatch({
      printer: this.LocalStorage.get('barcodePrinter'),
      barcode_template: this.LocalStorage.get('barcodeTemplate'),
      productionProduct: this.productionProduct.id,
      product: this.productionProduct.orderProduct.product.id,
      quantity: this.quantityPerContainer,
      batchSize: this.batchSize,
      event: this.data.event.id,
      moveToStore: moveToStore ? true : [],
      warehouseLocation: this.warehouseLocation?.id || [],
      printLabel: false,
    }).then((response: any) => {
      if (this.isAutoCloseDialog) this.dialogRef.close(1);
    })
  }

    showCopy = () => !!this.previousContainers;

    set = (property: string, value: any) => {
        this[property] = value;
    };

    calcQuantityPerContainer = () => {
        this.quantityPerContainer = +roundNumber(this.quantity / this.batchSize, 0);
    };

    setQuantityPerContainer(value: number) {
        this.quantityPerContainer = value;
        this.quantity = this.quantityPerContainer * this.batchSize;
    }

    setContainersQuantity(value: number) {
        this.batchSize = value;
        this.quantity = this.quantityPerContainer * this.batchSize;
    }

    setAutocloseDialog(value: boolean) {
        this.isAutoCloseDialog = value;
        this.UiPrefs.set(UI_CLOSE_AFTER_BARCODE_PRINT, value);
    }

    setQuantity(value: number) {
        this.quantity = value;
    }

    setWarehouseLocation(warehouseLocation: WarehouseLocation) {
        this.warehouseLocation = warehouseLocation;
    }

}
